import React, { useState, useEffect } from "react";
import "../css/Home.css"; // Updated import path

function Home() {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const windowHeight = window.innerHeight;

      // Adjust the condition based on when you want the button to fade out
      if (scrollPosition > windowHeight * 0.05) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <div className="home-container text-center d-flex align-items-center justify-content-center">
        <div>
          <h1 className="name" data-aos="fade-up">
            Ian Zhang
          </h1>
          <p className="description" data-aos="fade-up" data-aos-delay="200">
            A passionate web developer with a love for creating interactive and
            dynamic web applications. With a strong foundation in JavaScript,
            React, and modern web technologies, I am dedicated to delivering
            user-friendly and visually appealing solutions. 
            <br></br>
            <br></br>
            Welcome to my portfolio!
          </p>
        </div>
      </div>
      <span className={`description scroll-down-button ${isScrolled ? "fade-out" : ""}`}>
      ↓
      </span>
    </>
  );
}

export default Home;
